import classes from "./css/PaymentTypes.module.css";

import React, { Fragment } from "react";
import Creditcard from '../images/credit-card.png';
import NetBanking from '../images/bank.png';
import UPI from '../images/upi-logo.png';
import ICICIPayLater from '../images/icici.png';
import WezboLogo from '../images/wezbocloudlogo.png'
import Payment from "./Payment";
import CardPayment from "./PaymentTypes/CardPayment";
import { useState } from "react";
import { useContext } from "react";
import { PaymentContext } from "./context/PaymentContext";
import Netbanking from "./PaymentTypes/Netbanking";

import DownArrow from "../images/downArrow.svg"

const PAYMENT_TYPES = [
  { id: "cardPaymentId", name: "Card", image: Creditcard },
  { id: "netbankingPaymentId", name: "Netbanking", image: NetBanking },
  { id: "upiPaymenId", name: "UPI", image: UPI },
  { id: "iciciPaymentId", name: "ICICI PayLater", image: ICICIPayLater },
];


const heightOutput = window.screen.height;
const widthOutput = window.screen.width;

const fontSizePayment = (1.1 / 100) * widthOutput;
const fontSizeMobilePayment = (3.2 / 100) * widthOutput;
const webFontSizepayment = (1.3 / 100) * widthOutput;
const mobileFontSIze = (widthOutput < 720);

const demo = 1280
let mobileFontSizePaymentName = (1280 > widthOutput < 720);

const fontPaymentFinalWeb = mobileFontSizePaymentName ? fontSizePayment : (mobileFontSIze ? fontSizeMobilePayment : webFontSizepayment)

const fontSizeSelectPaymentMode = (4 / 100) * widthOutput;

const downArrowWidth = (2.5 / 100) * widthOutput;

const toShowMobileLayout = (widthOutput > 720);

console.log("checking payment mode", mobileFontSizePaymentName, fontPaymentFinalWeb, fontSizeMobilePayment, fontSizePayment, webFontSizepayment)



const PaymentTypes = (props) => {
  const { setPaymentMode } = useContext(PaymentContext);
  const [netBankActive, setNetBankActive] = useState(false);
  const [card, setCard] = useState(false);
  const [netBanking, setNetBanking] = useState(false);


  const [paymentTypeClose, setPaymentTypeClose] = useState(true);

  // const showMobileLayout = toShowMobileLayout ? paymentTypeClose : false;

  const { handleClickRef, show, showPaymentPageHandler, onPaymentSelection, onBeforePaymentSelection } = props;

  const handleClick = (paymentOption) => {
    showPaymentPageHandler()
    onPaymentSelection(paymentOption.name)
    setPaymentMode(paymentOption.name)
    setNetBankActive(true)
    // setPaymentTypeClose(false)
    toShowMobileLayout ? setPaymentTypeClose(true) : setPaymentTypeClose(false)

    if (paymentOption.name === "Card") {
      setCard(true);
      setNetBanking(false);
    } else if (paymentOption.name === "Netbanking") {
      setNetBanking(true);
      setCard(false);
    }




  }





  return (
    <Fragment>
      <div className="paymentMainDIvUpper_PaymentType">
        <div className="paymentModeMobile">
          <h1 style={{
            fontSize: `${fontSizeSelectPaymentMode}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }} onClick={() => setPaymentTypeClose(true)}>Select payment Mode &nbsp;
            {
              paymentTypeClose ? null : <img style={{ width: `${downArrowWidth}px` }} src={DownArrow} />
            } </h1>
        </div>
        {
          paymentTypeClose ?
            <div className="paymentTyes_MainDiv_PaymentType">
              <div className={classes.container} style={{ position: "relative", width: "100%", height: "100%" }}>
                <div className="paymentMainInnerDiv_PaymentType">
                  {PAYMENT_TYPES.map((paymentOption) => {
                    return (<>

                      <button onClick={() => handleClick(paymentOption)}
                        className="NetBankingCard_PaymentMode"
                      >
                        <div className="NetBankingImgDiv_PaymentMode">
                          <img src={paymentOption.image} />
                        </div>
                        <div className="NetBankingNameDiv_PaymentMode">
                          <h3 style={{
                            fontSize: `${fontPaymentFinalWeb}px`
                          }}>{paymentOption.name}</h3>
                        </div>
                      </button>

                      {/* <div key={paymentOption.id} onClick={() => handleClick(paymentOption)} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <img src={paymentOption.image} style={{ width: "100%", height: 50, objectFit: "contain", cursor: "pointer" }} />
                  <p style={{ textAlign: "center" }}>{paymentOption.name}</p>
                </div> */}


                    </>)
                  })}
                </div>
              </div>
            </div> : null
        }
      </div>

    </Fragment >
  );
};

export default PaymentTypes;
