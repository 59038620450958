import { useState, useEffect } from "react";
import { createPortal } from "react-dom"
import { Circles } from "react-loader-spinner";
import LoaderIcon from "../images/wezbo_doddle.png"
import "../components/css/ImageLoader.css"

const widthOutput = window.screen.width;

const loderImgWidth = (10 / 100) * widthOutput;
const heightOutput = window.screen.height;

const marginTopLoader = (heightOutput / 3)

export const Spinner = ({ isLoading }) => {
    if (!isLoading) return <></>

    return <div style={{
        // height: `${heightOutput}px`
    }} className="loaderMainDiv">
        <img
            style={{
                width: `${loderImgWidth}px`,
                top: `${marginTopLoader}px`

            }}
            className="rotate" src={LoaderIcon} visible={isLoading} />
    </div>

    // <Circles
    //     height="80"
    //     width="80"
    //     color="#4fa94d"
    //     ariaLabel="circles-loading"
    //     wrapperStyle={{
    //         display: "grid",
    //         placeContent: "center",
    //         width: "100%",
    //         position: "fixed",
    //         top: 0,
    //         bottom: 0
    //     }}
    //     wrapperClass=""
    //     visible={isLoading}
    // />
}

const Loader = () => {
    const [isLoading, setIsLoading] = useState(true)
    const loaderContainer = document.getElementById("loader-container")

    useEffect(() => { setTimeout(() => { setIsLoading(false) }, 500) }, []);

    if (isLoading) return createPortal(<Spinner isLoading={isLoading} />, loaderContainer)
    return createPortal(<></>, loaderContainer)

}

export default Loader;