import { useState } from "react";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import '../css/cardPayment.css'
import { useContext } from "react";
import { PaymentContext } from "../context/PaymentContext";

function CardPayment(props) {
  const { cardDetails, setCardDetails } = useContext(PaymentContext)

  const { cardNumber, expirationDate, cardHolderName, cvv } = cardDetails || {};

  const handleChange = (e) => {
    const { id, value } = e.target;
    setCardDetails({ ...cardDetails, [id]: value })
  }

  return (
    <>
      <div className="cardDetailsMainDiv_PaymentMode">
        <div className="cardDetailsInnerMianDiv_PaymentMode">
          <div className="cardDetailsNameDiv_PaymentMode">
            <h2>Name On Card</h2>
            <input
              className="holderNameInput_PaymentMode"
              placeholder="Enter Card Holder Name"
              id="cardHolderName"
              type="text"
              label="Card Holder Name"
              onChange={handleChange}
              value={cardHolderName}
            />
          </div>
          <div style={{
            paddingTop: "1rem"
          }} className="cardDetailsNameDiv_PaymentMode">
            <h2>Card Number</h2>
            <input
              className="CardNumberInput_PaymentMode"
              type="number"
              placeholder="XXXX - XXXX - XXXX - XXXX"
              id="cardNumber"
              label="Card Number"
              onChange={handleChange}
              value={cardNumber}
            />
          </div>
          <div className="cvv-expryMainDiv_PaymentMode">
            <div className="cvvMaiDiv_PaymentMode">
              <h2>CVV</h2>
              <input
                className="CVVInput_PaymentMode"
                placeholder="XXXX"
                id="cvv"
                type="number"
                label="CVV"
                onChange={handleChange}
                value={cvv}
              />
            </div>
            <div className="ExpMaiDiv_PaymentMode">
              <h2>Expiry Date</h2>
              <input
                className="ExpInput_PaymentMode"
                placeholder="MM / YY"
                id="expirationDate"
                type="month"
                label="Expiration Date"
                onChange={handleChange}
                value={expirationDate}
              />
            </div>
          </div>
        </div>
      </div>


      {/* <div className="cardPaymentMainDiv"> */}
      {/* <div className="cardPaymentInnerMainDiv">
          <Input

            id="cardHolderName"
            type="text"
            label="Card Holder Name"
            onChange={handleChange}
            value={cardHolderName}
          />
          <Input
            id="cardNumber"
            type="text"
            label="Card Number"
            onChange={handleChange}
            value={cardNumber}
          />
          <div>
            <div className="cardPaymentExpInput1" >
              <div className="cardPaymentExpInput1Inner" >
                <Input
                  id="expirationDate"
                  type="month"
                  label="Expiration Date"
                  onChange={handleChange}
                  value={expirationDate}
                />
              </div>
              <div className="cardPaymentExpInput1Inner2">
                <Input
                  id="cvv"
                  type="text"
                  label="CVV"
                  onChange={handleChange}
                  value={cvv}
                />
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className="cardPaymentBtn">
          <Button
            onClick={() =>
              props.intiatePayment({
                type: "Card",
                value: { cardNumber, expirationDate, cardHolderName, cvv },
              })
            }
          >
            Pay
          </Button>
        </div> */}
      {/* </div> */}
    </>
  );
}
export default CardPayment;
