import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import StripePage from "./Pages/StripePage";
// import Sucess from "./Pages/Success";
import Payment from "./components/Payment";
import NimbblSuccessPage from "./Pages/NimbbleSuccessPage";
import { PaymentContextProvider } from "./components/context/PaymentContext";
import Loader from "./components/Loader";

import LoaderIcon from "../src/images/WEZBO_Logo.svg"

const router = createBrowserRouter([
  {
    path: "/live",
    element: <MainPage />,
  },
  {
    path: "/in",
    element: <PaymentContextProvider><Payment /></PaymentContextProvider>,
  },
  {
    path: "/global",
    element: <StripePage />,
    loader: async () => {
      const clientSecret = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/order`);
      const clientSecretJson = await clientSecret.json();
      //console.log(clientSecretJson);
      return clientSecretJson;
    },
  },
  {
    path: "live/transaction/callback/:transaction_id/:auth_token",
    element: <NimbblSuccessPage />,
  },
]);

function App() {
  return (
    <div>
      <Loader
        src={LoaderIcon} // Replace with your image URL
        alt="Your Image Description"
      />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
