import React, { useState, useContext, useCallback } from "react";
import StoreContext from "../../store/store";
import useHttp from "../../hooks/use-http";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import UpperShade from '../../images/upperShade.svg';
import LowerShade from '../../images/downShade.svg';
import '../css/icici.css';
import { PaymentContext } from "../context/PaymentContext";



function IciciPayLater(props) {
  const storeCtx = useContext(StoreContext);
  const [otp, setOtp] = useState("");
  const [iciciId, setIciciId] = useState("")
  const { isLoading, error, sendRequest } = useHttp();
  const [isPaymentInitiated, setIsPaymentInitiated] = useState(false);
  const [isPaymentVerified, setIsPaymentVerified] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const { initiatePayment, } = useContext(PaymentContext)

  const paymentVerifyHandler = useCallback(() => {
    const payLoad = {
      userToken: storeCtx.user.token,
      order_id: storeCtx.orderId,
      paymentMode: "ICICI PayLater",
      type: "ICICI PayLater",
      otp: otp,
      transaction_id: storeCtx.transactionId,
      icici_id: iciciId,
    };

    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BACKEND_URL}/payment/complete/?token=` +
          encodeURIComponent(storeCtx.authToken),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: payLoad,
      },
      (response) => {
        if (response.status === "success") {
          setIsPaymentVerified(true);
        } else if (response.status === "failed") {
          setIsPaymentFailed(true);
        }
      }
    );
  }, [
    otp,
    sendRequest,
    storeCtx.authToken,
    storeCtx.orderId,
    storeCtx.user.token,
    storeCtx.transactionId,
    iciciId,
  ]);

  const iciciInitiateContent = (
    <>

      <div className="cardDetailsMainDiv_PaymentMode iciciMianDiv_paymentMode">
        <div className="cardDetailsInnerMianDiv_PaymentMode iciciInnerMianDiv_paymentMode">
          <div className="cardDetailsNameDiv_PaymentMode">
            <h2>Enter Your Mobile No.</h2>
            <input
              className="holderNameInput_PaymentMode"
              type="text"
              placeholder="Enter Mobile Number"
              id="iciciId"
              label=""
              onChange={(e) => setIciciId(e.target.value)}
              value={iciciId}
            />
          </div>
          <div className="getOtp_PaymentMode">
            <button
            style={{
              cursor:"pointer"
            }}
              onClick={() => {
                // props.intiatePayment({ type: "ICICI PayLater", value: iciciId });
                initiatePayment(initiatePayment)
              }}
            >
              Get OTP
            </button>
          </div>
        </div>
      </div>


      {/* <div className="IciciMainDiv">
        <div>
          <img src={UpperShade} className="upperShadeIcici" />
        </div>

        <div className="innerDivIcici">
          <h3>Mobile Number : {storeCtx.user.mobile_number} </h3>
          <Input
            id="iciciId"
            type="text"
            label=""
            onChange={(e) => setIciciId(e.target.value)}
            value={iciciId}
          />
          <div className="UPISentOtpBtn">
            <Button
              onClick={() => {
                // props.intiatePayment({ type: "ICICI PayLater", value: iciciId });
                initiatePayment(initiatePayment)
              }}
            >
              Send OTP
            </Button>
          </div>
        </div>
        <div>
          <img src={LowerShade} className="lowerShadeIcici" />
        </div>
      </div> */}

    </>
  );

  const iciciVerifyContent = (
    <>
      <Input
        id="otp"
        type="text"
        label="OTP"
        onChange={(e) => setOtp(e.target.value)}
        value={otp}
      />
      {/* <div className="IcicipayBtn">
        <Button onClick={paymentVerifyHandler}>Pay</Button>
      </div> */}
    </>
  );

  const iciciAfterVerifyContent = (
    <>
      {isPaymentVerified && <h3>Payment Verified</h3>}
      {isPaymentFailed && <h3>Payment Failed</h3>}
    </>
  );

  return (
    <>
      {!props.payButtonState && iciciInitiateContent}
      {props.payButtonState && iciciVerifyContent}
      {isPaymentVerified && iciciAfterVerifyContent}
    </>
  );
}

export default IciciPayLater;
