import { useCallback, useContext, useEffect } from "react";
import { createContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import StoreContext from "../../store/store";
import useHttp from "../../hooks/use-http";
import { red } from "@mui/material/colors";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const PaymentContext = createContext();

const defaultCardDetails = {
    cardNumber: "", expirationDate: "", cardHolderName: "", cvv: ""
}
const defaultNetBankingDetails = {
    bankName: ""
}
const defaultUpiDetails = {
    upiId: ""
}

const defaultIciciDetails = {}

export const PaymentContextProvider = ({ children }) => {
    const [paymentMode, setPaymentMode] = useState("Card")
    const storeCtx = useContext(StoreContext);
    const { payment_pay_id } = storeCtx
    const { isLoading, sendRequest } = useHttp();

    //
    const [isWaitingForConfirmation, setIsWaitingForConfirmation] =
        useState(false);
    const [ourTransactionId, setOurTransactionId] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [isPaymentInitialzed, setIsPaymentInitialzed] = useState(false);
    const [upiName, setUpiName] = useState("");
    const [payButtonIsDisabled, setPayButtonIsDisabled] = useState(true);

    const [cardDetails, setCardDetails] = useState(defaultCardDetails)
    const [netBankingDetails, setNetBankingDetails] = useState(defaultNetBankingDetails)
    const [upiDetails, setUpiDetails] = useState(defaultUpiDetails)
    const [iciciDetails, setIciciDetails] = useState(defaultIciciDetails)

    const [searchParams] = useSearchParams();

    const queryParams = new URLSearchParams(searchParams);
    const orderId = queryParams.get("orderId");
    const userToken = queryParams.get("userToken");




    const reset = useCallback(() => {
        setCardDetails(defaultCardDetails);
        setNetBankingDetails(defaultNetBankingDetails)
        setUpiDetails(defaultUpiDetails)
        setIciciDetails(defaultIciciDetails)
        if (["Netbanking", "Card"].includes(paymentMode)) {
            setPayButtonIsDisabled(false)
        } else {
            setPayButtonIsDisabled(true)
        }
    }, [paymentMode])

    useEffect(() => {
        reset()
    }, [reset])
    // useEffect(() => {
    //     setCardDetails(defaultCardDetails);
    //     setNetBankingDetails(defaultNetBankingDetails)
    //     setUpiDetails(defaultUpiDetails)
    //     setIciciDetails(defaultIciciDetails)
    //     if (["Netbanking", "Card"].includes(paymentMode)) {
    //         setPayButtonIsDisabled(false)
    //     } else {
    //         setPayButtonIsDisabled(true)
    //     }
    // }, [paymentMode])

    const convertDate = (e) => {
        const dateArray = e.split("-");
        return `${dateArray[1]}/${dateArray[0].slice(2)}`;
    };

    const initiatePayment = () => {
        const paymentPayId = localStorage.getItem("payment_pay_id")
        //console.log("===> initiatePayment payment_pay_id", paymentPayId)



        const payLoad = {
            userToken: userToken,
            order_id: orderId,
            paymentMode,
            type: paymentMode,
        };

        if (paymentMode === "Netbanking") {
            payLoad.bankName = netBankingDetails.bankName;
        }
        if (paymentMode === "Card") {


            payLoad.cardDetails = {
                cardNumber: cardDetails.cardNumber,
                expirationDate: convertDate(cardDetails.expirationDate),
                card_holder_name: cardDetails.cardHolderName,
                cvv: cardDetails.cvv,
            };
        }
        if (paymentMode === "UPI") {
            payLoad.upiId = upiDetails.upiId;
        }
        if (paymentMode === "ICICI PayLater") {
            //console.log("ICICI PayLater Payment Initiated");
        }




        sendRequest(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/payment/initiate`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: {
                    ...payLoad,
                    payment_pay_id: paymentPayId,
                },
            },

            (response) => {
                // console.log("===> RESPONSE IN INITIATE PAYMENT:", paymentMode === "UPI" && response.isValidVpa === "SUCCESS", response, response.transaction)
                //console.log("===> RESPONSE IN INITIATE PAYMENT:", response.isVPAValid)
                if (response.transaction) setOurTransactionId(response.transaction.transaction_id);

                if (paymentMode === "Netbanking" && response.status_code === 200) {
                    setRedirectUrl(response.extra_info.data.redirectUrl);
                    setIsPaymentInitialzed(true);
                }
                if (paymentMode === "Card" && response.status_code === 200) {
                    setRedirectUrl(response.extra_info.data.redirectUrl);
                    setIsPaymentInitialzed(true);
                }
                if (paymentMode === "UPI" && response.isVPAValid === 1) {
                    //console.log("\n\n\n it should call", response.payerAccountName)
                    setUpiName(response.payerAccountName);
                    setPayButtonIsDisabled(false);
                }
                if (paymentMode === "ICICI PayLater" && response.status_code === 200) {
                    setPayButtonIsDisabled(false);
                }


                if (response.isVPAValid === 0) {
                    toast.error("Please Enter Valid UPI or VPA", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        );
        //console.log("===>upiPaymentHandler initiatePayment", payLoad)
    };

    const upiPaymentHandler = () => {
        //console.log("====> upiPaymentHandler", ourTransactionId)
        let payLoad = {
            userToken: userToken,
            order_id: orderId,
            paymentMode: "UPI",
            type: "UPI",
            upi_id: upiDetails.upiId,
            transaction_id: ourTransactionId
        };
        sendRequest(
            {
                url:
                    `${process.env.REACT_APP_BACKEND_URL}/payment/complete/?token=` +
                    encodeURIComponent(storeCtx.authToken),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payLoad,
            },
            (response) => {
                storeCtx.setDbTransactionId(response.transaction.transaction_id);
                storeCtx.setTransactionId(response.transaction_id);
                setIsPaymentInitialzed(true);
                setIsWaitingForConfirmation(true);
            }
        );
        //console.log("inside SENDrEQUEST",sendRequest.url)
    }



    //console.log("===> CONTEXT VALUES:", paymentMode, cardDetails, netBankingDetails, upiDetails,iciciDetails, ourTransactionId, redirectUrl, isPaymentInitialzed, upiName, payButtonIsDisabled, isWaitingForConfirmation)
    //console.log("===> CONTEXT VALUES: redirectUrl ****", redirectUrl, redirectUrl !== "")
    const values = {
        userToken,
        orderId,
        paymentMode,
        setPaymentMode,
        cardDetails,
        setCardDetails,
        netBankingDetails,
        setNetBankingDetails,
        upiDetails,
        setUpiDetails,
        iciciDetails,
        setIciciDetails,
        initiatePayment,
        isLoading,

        ourTransactionId,
        setOurTransactionId,
        redirectUrl,
        setRedirectUrl,
        isPaymentInitialzed,
        setIsPaymentInitialzed,
        upiName,
        setUpiName,
        payButtonIsDisabled,
        setPayButtonIsDisabled,

        isWaitingForConfirmation,
        setIsWaitingForConfirmation,
        upiPaymentHandler,

        ToastContainer,



    }

    return <PaymentContext.Provider value={values}>
        {children}
    </PaymentContext.Provider>
}