import { useState, useContext, useEffect } from "react";
import StoreContext from "../../store/store";
import useHttp from "../../hooks/use-http";
import Button from "../../UI/Button/Button";
import UpperShade from '../../images/upperShade.svg';
import LowerShade from '../../images/downShade.svg';
import '../css/netbanking.css';
import { PaymentContext } from "../context/PaymentContext";
import DropDown from "../../images/downArrow.svg"


const widthOutPut = window.screen.width;

const bankDropDownheight = (3 / 100) * widthOutPut;

function Netbanking(props) {
  const storeCtx = useContext(StoreContext);
  const { userToken, orderId, netBankingDetails, setNetBankingDetails } = useContext(PaymentContext);
  const { sendRequest: getBankList } = useHttp();

  const [bankList, setBankList] = useState([]);
  const [netBankinDropDown, setNetBankingDropDown] = useState(false);

  const handleOptionChange = (e) => {
    setNetBankingDetails({ bankName: e.target.value });
  };

  useEffect(() => {
    getBankList(
      {
        url:
          `${process.env.REACT_APP_BACKEND_URL}/list-of-banks/?token=` +
          encodeURIComponent(userToken) +
          "&orderId=" +
          encodeURIComponent(orderId),
      },
      (data) => {
        setBankList(data);
      }
    );
  }, []);

  console.log("checking bank list", bankList, "<<<--->>", setNetBankingDetails)

  return (<>
    <div className="cardDetailsMainDiv_PaymentMode">
      <div className="cardDetailsInnerMianDiv_PaymentMode">
        <div className="cardDetailsNameDiv_PaymentMode" >
          <h2>Select An Option</h2>
          {/* <div onClick={() => setNetBankingDropDown(!netBankinDropDown)} style={{ cursor: "pointer" }}
            value={netBankingDetails.bankName} onChange={handleOptionChange}
          >
            <h3 className="dropDownNetbank_paymentMode"
              value={netBankingDetails.bankName} onChange={handleOptionChange}
            >
              Select Here
              {
                netBankinDropDown ? <img style={{ transform: "rotate(180deg)" }} src={DropDown} /> : <img src={DropDown} />
              }

            </h3>
          </div> */}

          <div
            className={netBankinDropDown ? "netBankingBankList_PaymentMode" : "hideScrollBar"}>
            {/* <select className="bankList_paymentMode" value={netBankingDetails.bankName} onChange={handleOptionChange}>
              <option value="">Select an option</option>
              {netBankinDropDown ? bankList.map((bank) => (
                <option key={bank.bank_name} value={bank.bank_name}>
                  <h1>{bank.bank_name}</h1>
                </option>
              )) : null}
            </select> */}


            {/* {netBankinDropDown ?
              bankList?.map((bank) => {
                return (<>
                  <div key={bank.bank_name} className="bankList_paymentMode" value={bank.bank_name}>
                    <h3>
                      {bank.bank_name}
                    </h3>
                  </div>
                </>)
              })
              : null} */}
            <div className="InnerMainDivNetBanking">
              <select style={{
                height: `${bankDropDownheight}px`
              }} className="selectNetBanking" value={netBankingDetails.bankName} onChange={handleOptionChange}>
                <option  value="">Select an option</option>
                {bankList.map((bank) => (
                  <option key={bank.bank_name} value={bank.bank_name}>
                    {bank.bank_name}
                  </option>
                ))}
              </select>
            </div>
          </div>

        </div>
      </div>
    </div >




    {/* <div className="NetBankingmainDiv">
      <div>
        <img src={UpperShade} className="UpperShadeNetBanking" />
      </div>

      <div className="InnerMainDivNetBanking">
        <select className="selectNetBanking" value={netBankingDetails.bankName} onChange={handleOptionChange}>
          <option value="">Select an option</option>
          {bankList.map((bank) => (
            <option key={bank.bank_name} value={bank.bank_name}>
              {bank.bank_name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <img src={LowerShade} className="lowerShadeNetBanking" />
      </div>
    </div> */}

  </>);
}

export default Netbanking;
