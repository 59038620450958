import React, { useEffect } from 'react';

const PaymentPage = (props) => {
  useEffect(() => {
    // Simulating a redirect to the payment gateway
    redirectToPaymentGateway();
  }, []);

  const redirectToPaymentGateway = () => {

    window.location.href = props.redirectUrl;
  };

  useEffect(() => {
    // This useEffect will execute when the component mounts or updates after returning from the payment gateway

    // Check if the URL contains the necessary payment response parameters
    const queryParams = new URLSearchParams(window.location.search);
    const paymentStatus = queryParams.get('status');
    const transactionId = queryParams.get('transactionId');

    if (paymentStatus === 'success') {
      // Payment was successful, update application state or trigger actions accordingly
      //console.log('Payment successful! Transaction ID:', transactionId);
    } else if (paymentStatus === 'cancel') {
      // Payment was canceled or failed, handle appropriately
      //console.log('Payment canceled or failed.');
    } else {
      // Invalid payment status or missing parameters
      //console.log('Invalid payment response.');
    }
  }, []);

  return (
    <div>
      
      {/* <h1>Payment Page</h1>
      <p>Redirecting to payment gateway...</p> */}
    </div>
  );
};

export default PaymentPage;






