import { Fragment, useEffect, useCallback, useContext, useState } from "react";
import Button from "../UI/Button/Button";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import StoreContext from "../store/store";



function MainPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const storeCtx = useContext(StoreContext);

  const params = new URLSearchParams(searchParams);
  const payment_pay_id = params.get("payment_pay_id");
  const authToken = params.get("auth_token");

  console.log("checking SetItem", payment_pay_id, authToken, params, storeCtx)

  storeCtx.setAuthToken(authToken);

  const { setPaymentPayId } = useContext(StoreContext);
  setPaymentPayId(payment_pay_id);
  localStorage.setItem("payment_pay_id", payment_pay_id);







  const navigate = useNavigate();

  const getOrderDetails = useCallback(async () => {
    const ipData = await fetch(`https://api.ipify.org/?format=json`);
    const data = await ipData.json();

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${authToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // fetch(
    //   "{process.env.REACT_APP_BACKEND_URL}/payment?payment_pay_id=cll6g8jyt0003qsmuxz9qliqv&ip_address=1.32.232.0",
    //   requestOptions
    // )
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.log("error", error));

    const orderDetails = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/payment?payment_pay_id=${payment_pay_id}&ip_address=${data.ip}`,
      requestOptions
    )

    const orderDetailsJson = await orderDetails.json();///// Fetch data is comming from here
    console.log("inside usedData", orderDetailsJson);

    localStorage.setItem("orderDetail", JSON.stringify(orderDetailsJson))


    if (orderDetailsJson.gateway === 'Nimbbl') {
      const nimbbleJson = orderDetailsJson.nimbblPaymentJson;
      const nimbbleJsonObj = JSON.parse(nimbbleJson);
      const userToken = nimbbleJsonObj.user.token;
      const orderId = nimbbleJsonObj.order_id;

      navigate(`/in?userToken=${userToken}&orderId=${orderId}`)

    } else if (orderDetailsJson.gateway === 'Stripe') {
      navigate('/global')
    }
  }, []);

  useEffect(() => {
    getOrderDetails();
  });

  return (
    <Fragment>
      {/* <Link to={"/stripe"}>
        <Button>Stripe</Button>
      </Link>
      <Link to={"/nimbbl"}>
        <Button>Nimbble</Button>
      </Link> */}
    </Fragment>
  );
}

export default MainPage;
