import React, { useState } from "react";

const StoreContext = React.createContext({
  authToken: "",
  user: {},
  orderId: "",
  transactionId: "",
  setAuthToken: (token) => {},
  setUser: (user) => {},
  setOrderId: (orderId) => {},
  setTransactionId: (transactionId) => { },
  payment_pay_id: "",
  setPaymentPayId: (payment_pay_id) => { },
  setDbTransactionId: (dbTransactionId) => { },
  dbTransactionId: "",
});

export const StoreContextProvider = (props) => {
  const [authToken, setAuthToken] = useState("");
  const [user, setUser] = useState({});
  const [orderId, setOrderId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [payment_pay_id, setPaymentPayId] = useState("");
  const [dbTransactionId, setDbTransactionId] = useState("")

  const dbTransactionIdHandler = (dbTransactionId) => {
    setDbTransactionId(dbTransactionId);
  };

  const paymentPayIdHandler = (payment_pay_id) => {
    setPaymentPayId(payment_pay_id);
  }

  const tokenHandler = (token) => {
    setAuthToken(token);
  };

  const userHandler = (user) => {
    setUser(user);
  };

  const orderIdHandler = (orderId) => {
    setOrderId(orderId);
  };

  const transactionIdHandler = (transactionId) => {
    setTransactionId(transactionId);
  };

  return (
    <StoreContext.Provider
      value={{
        authToken,
        user,
        orderId,
        transactionId,
        setAuthToken: tokenHandler,
        setUser: userHandler,
        setOrderId: orderIdHandler,
        setTransactionId: transactionIdHandler,
        payment_pay_id,
        setPaymentPayId: paymentPayIdHandler,
        dbTransactionId,
        setDbTransactionId: dbTransactionIdHandler
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
