import React from 'react';

import classes from './BackArrowButton.css';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs'

const BackArrowButton = (props) => {
    return (
        <BsArrowLeft style={{cursor:"pointer",width:"5%",height:"5%",marginLeft:"2%",marginTop:"2%"}}
            type={props.type || 'backArrow'}
            className={`${classes.backArrow} ${props.className}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </BsArrowLeft>
    );
};

export default BackArrowButton;