import React, { useCallback, useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";

function NimbblSuccessPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { transaction_id, auth_token } = useParams();

  // console.log({ transaction_id, auth_token });
  const params = new URLSearchParams(searchParams);

  const response = params.get("response");

  const responseObj = JSON.parse(atob(response));

  // console.log(responseObj);
  let transactionId = "";
  let status = "";

  if (responseObj.transaction) {
    transactionId = responseObj.transaction.transaction_id;
    status = responseObj.transaction.status;
  } else {
    transactionId = responseObj.transaction_id;
    status = responseObj.status;
  }

  const getOrderDetails = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/order/detail?transaction_id=${transaction_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth_token,
        },
      }
    );
    const data = await response.json();
    return data;
  });

  useEffect(() => {
    getOrderDetails().then((data) => {
      // console.log(data);
      const transactionId = data.transaction_id;
      const callbackUrl = data.payment_pay.callback_url;

      const dataResponse = {
        transaction_id: transactionId,
        status: status,
        auth_token: auth_token,
      };

      // encode in base64

      const encodedData = btoa(JSON.stringify(dataResponse));

      // console.log(callbackUrl);

      setTimeout(() => {
        // redirect to callback url
        window.location.href = `${callbackUrl}?response=${encodedData}`;
      },1000 );
    });
  }, [getOrderDetails]);

  // setTimeout(async() => {

  //   // const response = await getOrderDetails();

  //   console.log(response);

  //   const data = {
  //     payment_pay_id: payment_pay_id,
  //     transaction_id: transactionId,
  //     status: status,
  //     // invoice_id: invoice_id
  //   }

  // }, 5000);
  return (
    <div>
      <h1>{status} checking</h1>
      <p>Transaction Id:{transactionId}</p>
      <p>Please wait. Do not refresh the page or go back. You will be redirected shortly.</p>
    </div>
  );
}

export default NimbblSuccessPage;
