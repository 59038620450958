import classes from './css/CheckoutForm.module.css'
import { useStripe, useElements, PaymentElement, } from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
        elements,
      confirmParams: {
        return_url: "http://localhost:3000/stripe/success",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      //console.log(result.error.message);
    } else {
      // console.log(result);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
      <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className={classes.button} disabled={!stripe}>Submit</button>
    </form>
  )
};

export default CheckoutForm;