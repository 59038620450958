import React from 'react';

import classes from './Card.module.css';

const Card = (props) => {
  // console.log("consle in Card",props)
  return (<>
     <div className='mainDivCard'>
      <div className={`${classes.card} ${props.className}`}>{props.children}</div>
    </div>

  </>
  );
};

export default Card;
