import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import { useLoaderData } from "react-router-dom";


const stripePromise=loadStripe("pk_test_51NPhj7SExF5RYueeGiZArMhirjc4Jkcs59emGh8Yv5DEFTUrzeEvLwM2WuiXf75iSeER2jAcNz5A0QM3GEXVZrsd00WO13J3Sj")

function StripePage() {


    const loaderData = useLoaderData();
    // console.log(loaderData);
    const options = {
        // passing the client secret obtained from the server
        clientSecret: loaderData.client_secret,
    };


    return (
        <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    )
}

export default StripePage;