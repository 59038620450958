import { useState, useContext, useCallback, useEffect } from "react";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";

import StoreContext from "../../store/store";
import useHttp from "../../hooks/use-http";
import BackArrowButton from "../../UI/BackArrowButton/BackArrowButton";
import UpperShade from "../../images/upperShade.svg";
import LowerShade from "../../images/downShade.svg";
import "../css/upi.css";
import { PaymentContext } from "../context/PaymentContext";
import { useNavigate } from "react-router-dom";



const widthOutPut = window.screen.width;
const pandingStatusFontSize = (1.2 / 100) * widthOutPut
const pandingMobileStatusFontSize = (1 / 100) * widthOutPut;
const MobilePandingStatusFontSuze = (widthOutPut > 1280) ? pandingStatusFontSize : pandingMobileStatusFontSize;

function Upi() {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(150);
  const { sendRequest } = useHttp();
  const storeCtx = useContext(StoreContext);
  //console.log("storectx", storeCtx);
  const {
    validUpiNumber,
    ToastContainer,
    initiatePayment,
    payButtonIsDisabled,
    upiDetails,
    upiName,
    setUpiDetails,
    isWaitingForConfirmation,
    setIsWaitingForConfirmation,
    isPaymentInitialzed,
  } = useContext(PaymentContext);

  const [paymentVerified, setPaymentVerified] = useState(false);
  //console.log("inside UPI ValidUPINumber", validUpiNumber);

  const beforeIntialiizeContent = (
    <>

      <div style={{
        height: "-webkit-fill-available"
      }} className="cardDetailsMainDiv_PaymentMode iciciMianDiv_paymentMode">
        <div className="cardDetailsInnerMianDiv_PaymentMode iciciInnerMianDiv_paymentMode">

          {payButtonIsDisabled && (<>
            <div className="cardDetailsNameDiv_PaymentMode">
              <h2>Enter UPI ID or VPA ID</h2>
              <input
                className="holderNameInput_PaymentMode"
                type="text"
                placeholder="Enter ID Here"
                id="upiId"
                label="Enter UPI ID or VPA ID"
                onChange={(e) => setUpiDetails({ upiId: e.target.value })}
                value={upiDetails.upiId}
              />
            </div>
            <div className="getOtp_PaymentMode">
              <button style={{
                cursor: "pointer"
              }} onClick={initiatePayment}>
                Validate
              </button>
            </div>
            <ToastContainer />
          </>)}

          {!payButtonIsDisabled && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", alignItems: "center" }}>
              <h1 style={{
                fontSize: "1.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}><span style={{
                color: "rgb(132, 26, 68)"
              }}></span><span>{upiName}</span></h1>
            </div>
          )}
        </div>
      </div>


      {/* <div className="UpiMainDiv">
        <div>
          <img src={UpperShade} className="UpperShadeUPI" />
        </div>
        <div className="UpiInnerDiv">
          {payButtonIsDisabled && (
            <>
              <div>
                <Input
                  id="upiId"
                  type="text"
                  label="Enter UPI ID or VPA ID"
                  onChange={(e) => setUpiDetails({ upiId: e.target.value })}
                  value={upiDetails.upiId}
                />
              </div>
              <div className="BtnUpiValidate">
                <Button
                  onClick={initiatePayment}
                >
                  Validate
                </Button>
              </div>
              <ToastContainer />
            </>
          )}
          {!payButtonIsDisabled && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h1 style={{ fontSize: "1.5rem" }}>Name:- {upiName}</h1>
            </div>
          )}
        </div>
        <div>
          <img src={LowerShade} className="LowerShadeUpi" />
        </div>
      </div> */}
    </>
  );


  const getOrderDetails = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/order/detail?transaction_id=${storeCtx.dbTransactionId}&upiTransactionId=${storeCtx.transactionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + storeCtx.authToken,
        },
      }
    );
    const data = await response.json();
    return data;
  });

  const getTransactionStatus = useCallback(() => {
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BACKEND_URL}/transaction?token=` +
          encodeURIComponent(storeCtx.authToken) +
          "&by=" +
          encodeURIComponent("transactionId") +
          "&transactionId=" +
          encodeURIComponent(storeCtx.transactionId),
      },
      (response) => {
        //console.log("getTransactionStatus", response);
        if (response.status === "succeeded" || response.status === "failed") {
          if (response.status === "succeeded") {
            setIsWaitingForConfirmation(false);
            setTimer(150);
            setPaymentVerified(true);
          } else if (response.status === "failed") {
            setIsWaitingForConfirmation(false);
            setTimer(150);
            setPaymentVerified(false);
          }

          setTimeout(async () => {
            getOrderDetails().then((data) => {
              console.log(data);
              const transactionId = data.transaction_id;
              const callbackUrl = data.payment_pay.callback_url;
              console.log("callbackUrl", callbackUrl)
              const dataResponse = {
                transaction_id: transactionId,
                status: response.status,
                auth_token: storeCtx.authToken,
              };

              // encode in base64

              const encodedData = btoa(JSON.stringify(dataResponse));



              setTimeout(() => {
                // redirect to callback url

                window.location.href = `${callbackUrl}?response=${encodedData}`;
              }, 800);
            });
          })

        }
      }
    );
  }, [sendRequest, storeCtx.authToken, storeCtx.transactionId, getOrderDetails]);

  useEffect(() => {
    if (timer === 0) {
      setIsWaitingForConfirmation(false);
      setTimer(150);
    }
    if (isWaitingForConfirmation && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Send HTTP request every 10 seconds
      if (timer % 5 === 0) {
        getTransactionStatus();
      }
      //console.log("Timer", timer);

      return () => clearInterval(countdown);
    }
  }, [isWaitingForConfirmation, timer]);

  // console.log(
  //   "isWaitingForConfirmation====>>>>",
  //   isWaitingForConfirmation,
  //   paymentVerified,
  //   isPaymentInitialzed
  // );

  function convertSecondsToMinutesAndSeconds(seconds) {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;

    return minutes + " min and " + remainingSeconds + " sec";
  }

  const afterIntialiizeContent = (
    <>
      {isWaitingForConfirmation && !paymentVerified && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "-webkit-fill-available",
            }}
          >
            {/* <div>
              <img src={UpperShade} className="UpperShadeUPI" />
            </div> */}
            <div style={{
              textAlign: "center", fontSize: "0.7rem",
              height: "100%",
              background: "#f6f6f6",
              display: "flex",
              alignItems: "end",
              margin: "1rem",
              borderRadius: "10px"
            }}>
              <h1 style={{
                fontSize: `${MobilePandingStatusFontSuze * 1.3}px`,
                fontWeight: "500",
                height: "50%",
                display: "flex",
                justifyContent: "space-betwee",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                <span>Waiting for confirmation <br /> {convertSecondsToMinutesAndSeconds(timer)}</span> <br />
                <p style={{
                  width: "70%",
                  color: "rgb(132, 26, 68)",
                  fontSize: `${MobilePandingStatusFontSuze}px`,
                }}>Please wait. Do not refresh the page or go back. You will be redirected shortly.</p>
              </h1>
            </div>
            {/* <div>
              <img src={LowerShade} className="LowerShadeUpi" />
            </div> */}
          </div>
        </>
      )}
      {!isWaitingForConfirmation && !paymentVerified && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",

          }}
        >
          {/* <div>
            <img src={UpperShade} className="UpperShadeUPI" />
          </div> */}
          <div style={{
            textAlign: "center", fontSize: "0.7rem",
            height: "100%",
            background: "#f6f6f6",
            margin: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
            <h1 style={{
              paddingBottom: "2rem"
            }}>Payment Failed <br /> Sorry</h1>
            <p style={{
              color: "rgb(132, 26, 68)",
              textAlign: "center"
            }}>Please wait. <br></br> Do not refresh the page or go back. You will be redirected shortly.</p>
          </div>
          {/* <div>
            <img src={LowerShade} className="LowerShadeUpi" />
          </div> */}
        </div>
      )
      }
      {
        !isWaitingForConfirmation && paymentVerified && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "-webkit-fill-available",
            }}
          >
            {/* <div>
              <img src={UpperShade} className="UpperShadeUPI" />
            </div> */}
            <div style={{
              textAlign: "center", fontSize: "0.7rem",
              height: "100%",
              background: "#f6f6f6",
              display: "flex",
              alignItems: "end",
              margin: "1rem",
              borderRadius: "10px"
            }}>
              <h1 style={{
                fontSize: `${MobilePandingStatusFontSuze * 1.3}px`,
                fontWeight: "500",
                height: "50%",
                display: "flex",
                justifyContent: "space-betwee",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                <span>Payment Successful</span>
                <p style={{
                  width: "70%",
                  color: "rgb(132, 26, 68)",
                  fontSize: `${MobilePandingStatusFontSuze}px`,
                  textAlign: "center"
                }}>Please wait a moment while we finalize your transaction.<br></br> Do not refresh the page or navigate back, as you will be automatically redirected to your order confirmation shortly.</p>
              </h1>
            </div>
            {/* <div>
              <img src={LowerShade} className="LowerShadeUpi" />
            </div> */}
          </div>
        )
      }
    </>
  );

  return (
    <>
      {!isPaymentInitialzed && beforeIntialiizeContent}
      {isPaymentInitialzed && afterIntialiizeContent}
    </>
  );
}

export default Upi;
