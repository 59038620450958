import classes from "./css/PaymentModes.module.css";
import PaymentTypes from "./PaymentTypes";
import Card from "../UI/Card/Card";
import Upi from "./PaymentTypes/Upi";
import Netbanking from "./PaymentTypes/Netbanking";
import CardPayment from "./PaymentTypes/CardPayment";
import IciciPayLater from "./PaymentTypes/IciciPayLater";
import PaymentPage from "./PaymentPage";

import { useState, useContext, useRef } from "react";
import useHttp from "../hooks/use-http";
import BackArrowButton from "../UI/BackArrowButton/BackArrowButton";
import "../components/css/payment.css";
import WezboW from "../images/WEZBO_Logo.svg";
import StoreContext from "../store/store";

import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useCallback } from "react";
import Button from "../UI/Button/Button";
import { PaymentContext } from "./context/PaymentContext";
import { Spinner } from "./Loader";


const heigthOutPut = window.innerHeight;
const heightpaymentpage_payment = heigthOutPut;
const widthOutPut = window.screen.width;

const toShowMobileLayout = (widthOutPut > 720);


console.log("checking height of screen", heigthOutPut, window.innerHeight, widthOutPut, toShowMobileLayout);

const Payment = () => {
  const { payment_pay_id } = useContext(StoreContext);
  const { ToastContainer, toast, isLoading, initiatePayment, paymentMode, upiPaymentHandler, setPaymentMode,
    isPaymentInitialzed, payButtonIsDisabled, isWaitingForConfirmation, setPayButtonIsDisabled, redirectUrl } = useContext(PaymentContext);

  const disabledPayButton = isPaymentInitialzed || isWaitingForConfirmation || payButtonIsDisabled
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [showError, setShowError] = useState("")
  const [netBanking, setNetBanking] = useState(false);
  const ref = useRef(null);


  const [show, setShow] = useState(false);


  const showMobileLayout = toShowMobileLayout ? true : show

  const handleClick = () => {
    ref.current.focus();
  };



  const oderDetialsData = JSON.parse(localStorage.getItem("orderDetail"))

  console.log("oderDetialsData ====>>>>", showError,oderDetialsData)

  // toast.error({showError}, {
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  //   theme: "light",
  // });

  const PaymentBackButton = () => {
    return (
      <BackArrowButton
        onClick={() => {
          setPaymentMode("");
          setShowPaymentPage(false);
          setPayButtonIsDisabled(true);
        }}
      />
    );
  };

  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMjg2Mywic3ViX21lcmNoYW50X2lkIjoxNzk3OSwiZXhwIjoxNjkxNTg0NzI2LCJ0eXBlIjoibWVyY2hhbnQiLCJpYXQiOjE2OTE1ODM1MjYsImlzcyI6InVybjpuaW1iYmwiLCJ0b2tlbl90eXBlIjoidHJhbnNhY3Rpb24ifQ.5SIqdwYcipZq8BKVoRkDLsW3a2s1CxixaKpuIZlhp9A"
  );

  const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMjg2Mywic3ViX21lcmNoYW50X2lkIjoxNzk3OSwiZXhwIjoxNjkxNTg0NzI2LCJ0eXBlIjoibWVyY2hhbnQiLCJpYXQiOjE2OTDNEpUTHQoQUJMHLrErGJyHg89uy71MyuHiLCJ0b2tlbl90eXBlIjoidHJhbnNhY3Rpb24ifQ.5SIqdwYcipZq8BKVoRkDLsW3a2s1CxixaKpuIZlhp9A";
  var requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    },
    redirect: "follow",
  };

  //console.log("payment_pay_id", requestOptions, payment_pay_id)

  // const getOrderDetails = useCallback(async () => {
  //   const paymentPayId = localStorage.getItem("payment_pay_id");
  //   try {
  //     const ipData = await fetch(`https://api.ipify.org/?format=json`);
  //     const data = await ipData.json();
  //     const orderDetails = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}/payment?payment_pay_id=${paymentPayId}&ip_address=${data.ip}`,
  //       requestOptions
  //     )

  //     const orderDetailsJson = await orderDetails.json();     ///// Fetch data is comming from here
  //     console.log("orderDetailsJson", orderDetailsJson);
  //     setOrderDetails(orderDetailsJson);
  //   } catch (error) {
  //     setShowError(error)
  //     console.log("===> Error while fetching order details:", error)
  //   }

  // }, [])

  // console.log("into process . env",process.env.REACT_APP_BACKEND_URL)

  // useEffect(() => { getOrderDetails() }, [getOrderDetails]);

  const handlePayment = () => {
    if (disabledPayButton) return;
    if (paymentMode === "UPI") upiPaymentHandler()
    else initiatePayment()
  }

  const paymentModeSelectionHandler = (paymentMode) => {
    setPaymentMode(paymentMode);
    // console.log("payment mode", paymentMode);
  };

  const showPaymentPageHandler = () => {
    setShowPaymentPage(true);
  };

  const selectePaymentModeContent = () => {

    switch (paymentMode) {
      case "Netbanking":
        return <Netbanking />;

      case "ICICI PayLater":
        return <IciciPayLater />;

      case "UPI":
        return <Upi />;

      case "Card":
        return <CardPayment />;

      default:
        <p></p>;
    }
  };

  const contentBeforePaymentModeSelection = (
    <>
      {/* <PaymentTypes onPaymentSelection={paymentModeSelectionHandler} showPaymentPageHandler={showPaymentPageHandler} /> */}
      <div className={classes["button-container"]}>
        {/* <Button disabled={paymentMode === ""} onClick={showPaymentPageHandler}>
          Next
        </Button> */}
      </div>
    </>
  );

  const contentAfterPaymentModeSelection = (
    <>
      {selectePaymentModeContent()}

      {/* <Button
        onClick={() => {
          setPaymentMode("");
          setShowPaymentPage(false);
          setPayButtonIsDisabled(true);
        }}
      >
        Back
      </Button> */}
    </>
  );
  let total = (oderDetialsData?.amount)?.toFixed(2)
  let grandTotal = (oderDetialsData?.amount)?.toFixed(2)
  // let total = (oderDetialsData?.amount)
  // let grandTotal = (oderDetialsData?.amount)


  const contentBeforeInitiatePayment = (
    <>
      <div ref={ref} className="paymentOptionMainPage">
        <div className="paymentOptionPage_payment">
          <div className="paymentType_payment">
            <div className="paymentMethod_paymentMain">
              <div className="paymentMethodUnner_payment">
                <div className="paymentMainPage_payment">

                  <PaymentTypes
                    onPaymentSelection={paymentModeSelectionHandler}
                    showPaymentPageHandler={showPaymentPageHandler}
                  />

                </div>
                <div className="paymentMethod_payment">
                  <div className="paymentMethodInnerDiv_payment">
                    <div className="paymentModeinnerMainDiv_Payment">
                      { }
                      {showMobileLayout && !showPaymentPage && contentBeforePaymentModeSelection && (
                        <CardPayment />
                      )}
                      {showPaymentPage && <CardPayment /> &&
                        contentAfterPaymentModeSelection}
                      {/* {console.log("inside log", showPaymentPage)} */}
                      {
                        showMobileLayout ?
                          <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingBottom: "2rem" }}>
                            <button disabled={disabledPayButton} onClick={handlePayment}
                              style={{
                                width: "95%",
                                margin: 0,
                                padding: 0,
                                height: "3rem",
                                borderRadius: "10px",
                                fontSize: "1.5rem",
                                border: "none",
                                color: "white",
                                backgroundColor: `${disabledPayButton ? "#878787 " : "#841A44"}`,
                                cursor: `${disabledPayButton ? "" : "pointer"}`
                              }}

                            >
                              Pay Now
                            </button>
                          </div>
                          : null
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="checkoutMianPage">
              <div className="summaryMainDiv_PaymentMode">
                <div className="summaryInnerDiv_PaymentMode">
                  <div className="summerHeader_PaymentMode">
                    <h2>
                      Order Summary
                    </h2>
                  </div>
                  <div className="paymentDetailsMainDiv_PaymentMode">
                    <div className="invoiceDiv_PaymentMode">
                      <h3 className="amountHeader_paymentMode">Invoice</h3>
                      <h3>{oderDetialsData?.inv_id || ""}</h3>
                    </div>
                    <div className="discountDiv_PaymentMode">
                      <h3 className="amountHeader_paymentMode">Amount</h3>
                      <h3>{oderDetialsData?.currency} {total}</h3>
                    </div>
                    <div className="grandTotal_PaymentMode">
                      <h3 className="amountHeader_paymentMode">Grand Total</h3>
                      <h3>{oderDetialsData?.currency} {grandTotal}</h3>
                    </div>
                  </div>
                  <div className="lowerMainDiv_PaymentMode">
                    <div className="lowerInnerMainDiv_PaymentMode">
                      <h3 className="havetoPay_PaymentMode">You Have To Pay</h3>
                      <div className="amountMainDiv_PaymentMode">
                        <h3 className="rupeeSymb_PaymentMode">₹</h3>
                        <h3 className="totalAmount_PaymentMode">
                          {grandTotal}
                        </h3>
                      </div>
                    </div>
                    {/* <div className="MobilePayBtn">
                    <button
                      style={{
                        backgroundColor: `${disabledPayButton ? "none" : "red"}`
                      }}
                      disabled={disabledPayButton} onClick={handlePayment}>
                      Pay Now
                    </button>
                  </div> */}
                  </div>

                </div>

              </div>




              {/* <div style={{ height: "80%" }}>
              <h1 className="summaryOrder"> Summary Order</h1>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h3 style={{ margin: "0" }}>Invoice</h3>
                    <h3 style={{ fontWeight: "200" }}>{oderDetialsData?.inv_id || ""}</h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "end",
                    }}
                  >
                    <h3 style={{ margin: "0" }}>Amount</h3>
                    <h3 style={{ fontWeight: "200" }}>{oderDetialsData.currency} {total}</h3>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ margin: "0" }}>Grand Total </h3>
                  <h3 style={{ margin: "0", fontWeight: "200" }}>
                    {oderDetialsData.currency} {grandTotal}
                  </h3>
                </div>
              </div>
            </div> */}
              {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Button disabled={disabledPayButton} onClick={handlePayment}>
                Pay
              </Button>
            </div> */}
              {/* {alert(showError)} */}
            </div>
          </div>

          {/* <div className="checkoutMianPage">

            <div style={{ height: "80%" }}>
              <h1 className="summaryOrder"> Summary Order</h1>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h3 style={{ margin: "0" }}>Invoice</h3>
                    <h3 style={{ fontWeight: "200" }}>{oderDetialsData?.inv_id || ""}</h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "end",
                    }}
                  >
                    <h3 style={{ margin: "0" }}>Amount</h3>
                    <h3 style={{ fontWeight: "200" }}>{oderDetialsData.currency} {total}</h3>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ margin: "0" }}>Grand Total </h3>
                  <h3 style={{ margin: "0", fontWeight: "200" }}>
                    {oderDetialsData.currency} {grandTotal}
                  </h3>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Button disabled={disabledPayButton} onClick={handlePayment}>
                Pay
              </Button>
            </div>
          </div> */}
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
  // console.log("in payment pages=======>>>>>>", showPaymentPage, "====After====>>>", contentAfterPaymentModeSelection, "=====Before====>>>>>>>>>>>", contentBeforeInitiatePayment)

  const pull_data = (data) => {
    console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  }

  return (
    <>
      <div style={{
        // height: `${heightpaymentpage_payment}px`
      }} className="paymentpage_payment">
        <div className="headerLogo">
          <img src={WezboW} />
        </div>
        <div>
          <Card>
            {redirectUrl === "" ? contentBeforeInitiatePayment : null}
            {isPaymentInitialzed && redirectUrl !== "" ? <PaymentPage redirectUrl={redirectUrl} show={show} handleClickRef={handleClick} /> : null}
          </Card>
          <Spinner isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Payment;
